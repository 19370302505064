<template>
  <div v-if="upcomingRepayments.length < 1">
    <div
      class="mx-auto mt-3 flex justify-center rounded-lg bg-white pb-56 pt-20 text-center"
    >
      <div>
        <div class="mx-auto h-[120px] w-[140px] lg:h-[123px] lg:w-[165px]">
          <the-icon
            icon-name="no-repayment"
            size="auto"
            class-name="fill-none"
          />
        </div>
        <h3 class="mt-3 text-base font-bold text-primary lg:mt-8 lg:text-xl">
          No upcoming repayments for now
        </h3>
        <p class="mt-3 max-w-sm text-sm font-normal text-gray-600">
          You can track your upcoming repayments here
        </p>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="flex items-start justify-between border-b border-t bg-white px-4 py-5"
    >
      <p class="text-sm font-medium text-primary">Due date</p>
      <p class="text-sm font-medium text-primary">Total repayment amount</p>
    </div>
    <div>
      <div class="custom-scrollbar max-h-[600px] overflow-auto">
        <div v-for="(item, idx) in paginatedRepayments" :key="idx" class="">
          <div
            class="flex cursor-pointer items-start justify-between border-b bg-white px-4 py-5 last:border-none"
            @click.prevent="toggleViewVendor(item.due_date)"
          >
            <div class="inline-flex items-center gap-x-3">
              <div>
                <svg
                  :class="
                    activeViewedRepaymentDates.has(item.due_date)
                      ? '-rotate-90'
                      : ''
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="#475569"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <p class="text-sm font-medium text-primary">
                {{
                  item.due_date
                    ? dayjs(item.due_date).format('DD-MM-YYYY')
                    : '-'
                }}
              </p>
              <div
                class="rounded-full bg-[#E7EEFF] px-1.5 py-0.5 text-xs font-normal text-[#475467]"
              >
                {{ item.repayments.length + ' ' + 'bill(s) found' }}
              </div>
              <div
                v-if="item.due_date && dayjs().isAfter(item.due_date)"
                class="flex items-center gap-x-2 text-sm text-primary"
              >
                <div class="h-2.5 w-2.5 rounded-full bg-yellow-500"></div>
                Payment overdue
              </div>
            </div>
            <p class="text-sm font-medium text-primary">
              {{
                new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 2,
                }).format(item.amount || 0)
              }}
            </p>
          </div>
          <div
            :class="
              !activeViewedRepaymentDates.has(item.due_date) ? 'hidden' : ''
            "
          >
            <div class="hidden xl:block xl:border-0 xl:bg-transparent">
              <div class="px-4 py-2">
                <div class="grid grid-cols-1 gap-x-2 md:grid-cols-6">
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Original Invoice Date
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Vendor Paid
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Principal Amount
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Fee Amount
                  </div>
                  <div class="w-[254px] text-sm font-medium text-primary">
                    Repayment Amount
                  </div>
                  <div class="text-sm font-medium text-primary"></div>
                </div>
              </div>
              <div class="cursor-pointer bg-[#F1F5F9] px-4 py-2">
                <div
                  v-for="(x, index) in item.repayments"
                  :key="index"
                  class="grid grid-cols-1 gap-x-2 py-2 md:grid-cols-6"
                >
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{ dayjs(x.invoice_date).format('DD-MM-YYYY') }}
                  </div>
                  <div
                    class="cursor-pointer truncate text-sm font-medium text-primary"
                    style="text-transform: capitalize"
                  >
                    {{ x.vendor_name }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_principal_amount || 0)
                    }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_fee_amount || 0)
                    }}
                  </div>
                  <div class="cursor-pointer text-sm font-medium text-primary">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_repayment_amount || 0)
                    }}
                  </div>
                  <button
                    class="flex w-max items-center gap-1 text-sm text-primary disabled:cursor-wait disabled:opacity-60"
                    :disabled="isDownloadingInvoice(x.invoice_url)"
                    @click.prevent="downloadInvoice(x.invoice_url)"
                  >
                    <ph-spinner
                      v-if="isDownloadingInvoice(x.invoice_url)"
                      class="animate-spin text-current"
                      :size="20"
                    />
                    <ph-paperclip v-else :size="20" />

                    Download invoice
                  </button>
                </div>
              </div>
            </div>
            <div class="xl:hidden">
              <div
                v-for="(x, index) in item.repayments"
                :key="index"
                class="relative mb-2 flex items-start justify-between bg-[#F1F5F9] px-4 py-5"
              >
                <div
                  class="flex flex-col gap-3 text-sm font-medium text-primary"
                >
                  <div class="">Original Invoice Date</div>
                  <div class="">Vendor Paid</div>
                  <div class="">Principal Amount</div>
                  <div class="">Fee Amount</div>
                  <div class="">Repayment Amount</div>
                  <div class=""></div>
                </div>
                <div
                  class="flex flex-col gap-3 text-sm font-normal text-primary"
                >
                  <div class="">
                    {{ dayjs(x.invoice_date).format('DD-MM-YYYY') }}
                  </div>
                  <div class="">
                    {{ x.vendor_name }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_principal_amount || 0)
                    }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_fee_amount || 0)
                    }}
                  </div>
                  <div class="">
                    {{
                      new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                      }).format(x.monthly_repayment_amount || 0)
                    }}
                  </div>
                </div>
                <div
                  class="absolute inset-x-0 bottom-0 flex items-center justify-center"
                >
                  <span
                    class=""
                    @click.prevent="downloadInvoice(x.invoice_url)"
                  >
                    <a
                      href="#"
                      class="inline-flex gap-x-1 text-sm text-primary underline"
                    >
                      <the-icon
                        icon-name="clip"
                        size="xs"
                        class="fill-none"
                      />Download invoice</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between p-3">
        <div class="hidden items-center gap-x-3 lg:flex">
          <div>
            <Select v-model="itemsPerPage">
              <SelectTrigger class="h-10 w-[70px]">
                <SelectValue :placeholder="String(itemsPerPage)" />
              </SelectTrigger>
              <SelectContent side="top" class="min-w-[100px]">
                <SelectItem
                  v-for="pageSize in ['10', '20', '30', '40', '50']"
                  :key="pageSize"
                  :value="pageSize"
                >
                  {{ pageSize }}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <span class="text-sm font-medium text-slate-500">rows per page</span>
        </div>
        <div v-if="paginationInfo && paginationInfo.totalPages > 1">
          <Pagination
            :total="paginationInfo.total"
            :sibling-count="1"
            :items-per-page="itemsPerPage"
            show-edges
            :default-page="page"
          >
            <PaginationList v-slot="{ items }" class="flex items-center gap-1">
              <PaginationFirst @click="() => goToPage(1)" />
              <PaginationPrev
                @click="() => paginationInfo.hasPrevPage && goToPage('back')"
              />

              <template v-for="(item, index) in items">
                <PaginationListItem
                  v-if="item.type === 'page'"
                  :key="index"
                  :value="item.value"
                  as-child
                >
                  <Button
                    class="h-10 w-10 p-0"
                    :variant="item.value === page ? 'default' : 'outline'"
                    @click="() => goToPage(item.value)"
                  >
                    {{ item.value }}
                  </Button>
                </PaginationListItem>
                <PaginationEllipsis v-else :key="item.type" :index="index" />
              </template>

              <PaginationNext
                @click="
                  () => paginationInfo!.hasNextPage && goToPage('forward')
                "
              />
              <PaginationLast
                @click="() => goToPage(paginationInfo.totalPages)"
              />
            </PaginationList>
          </Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { PhSpinner, PhPaperclip } from '@phosphor-icons/vue'
import TheIcon from '@/components/shared/the-icon.vue'
import { useToast } from '~/components/ui/toast/use-toast'
import type { GetUpcomingRepaymentsResponse } from '~/types/apiResponse/repayments.response'

interface Props {
  upcomingRepayments: GetUpcomingRepaymentsResponse
}

const props = defineProps<Props>()

const page = ref(1)
const itemsPerPage = ref('20')
const itemsPerPageNUMBER = computed(() => +itemsPerPage.value)

const {
  data: paginatedRepayments,
  goToPage,
  paginationInfo,
} = useLocalPagination(
  computed(() => props.upcomingRepayments),
  page,
  itemsPerPageNUMBER,
)

watch(itemsPerPage, () => {
  page.value = 1
})

const activeViewedRepaymentDates = ref(new Set<string>())

const { $lenkieBankingApi } = useNuxtApp()

const toggleViewVendor = (id: string) => {
  const isPresent = activeViewedRepaymentDates.value.has(id)

  if (isPresent) {
    activeViewedRepaymentDates.value.delete(id)
  } else {
    activeViewedRepaymentDates.value.add(id)
  }
}

const { toast } = useToast()

const currentlyDownloadingURLs = ref<string[]>([])

function addOrRemoveToCurrentDownloadURLs(url: string) {
  const index = currentlyDownloadingURLs.value.indexOf(url)
  if (index === -1) {
    currentlyDownloadingURLs.value.push(url)
  } else {
    currentlyDownloadingURLs.value.splice(index, 1)
  }
}

function isDownloadingInvoice(url: string) {
  return currentlyDownloadingURLs.value.includes(url)
}

const downloadInvoice = async (urls: string) => {
  try {
    addOrRemoveToCurrentDownloadURLs(urls)
    let fileCount = 0
    for (const url of urls.split(',')) {
      const response = await $lenkieBankingApi.get(
        `/FileUpload/downloadFile?filePath=${url.trim()}`,
        {
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })

      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = url.trim()
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      fileCount++
    }
    const toastMessage = `${fileCount} ${fileCount < 2 ? 'file' : 'files'}  downloaded successfully`
    toast({
      title: 'Invoice download',
      description: toastMessage,
    })
  } catch (error) {
    toast({
      title: 'Failed to download invoice',
      description: 'An error occured, please contact support',
      variant: 'destructive',
    })
  } finally {
    addOrRemoveToCurrentDownloadURLs(urls)
  }
}
</script>
